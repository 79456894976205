<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">当前：产品销售排行榜</strong>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :lg="24">
                            <el-row>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-date-picker
                                            style=" margin-top: 8px; width: 220px"
                                            v-model="pickerDate"
                                            type="daterange"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            align="right">
                                    </el-date-picker>
                                </el-col>

                                <el-col :lg="4" >
                                    <el-button style=" margin-top: 8px; " id="createBill" type="primary" round  size="mini" @click="searchList()">搜索查询</el-button>
                                    <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                               @click="open($refs.xGrid.getCurrentRecord())" size="mini">查看
                                    </el-button>
                                </el-col>

                                <el-col :lg="6" :sm="12" :md="8">
                                    <el-row>
                                        <el-col class="pull-left" :lg="4">
                                            <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export ref="xToolbar2">
                                            </vxe-toolbar>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
<el-row>
    <el-col :lg="24">
        <vxe-grid
                  border
                  resizable
                  show-overflow
                  keep-source
                  ref="xGrid"
                  size="mini"
                  :height="(this.getViewHeight() - 130)+''"
                  highlight-hover-row
                  highlight-current-row
                  show-footer
                  :footer-method="footerMethod"
                  :print-config="{}"
                  :data="tableDataMain"
                  :columns="tableColumn"
                  :mouse-config="{selected: false}"
                  :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                  :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
            <template #pager>
                <vxe-pager
                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                        :current-page.sync="tablePage.currentPage"
                        :page-size.sync="tablePage.pageSize"
                        :total="tablePage.total"
                        @page-change="handlePageChange">
                </vxe-pager>
            </template>
        </vxe-grid>
    </el-col>


</el-row>

                </el-form>
            </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 7), new Date()],
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'seq', title: '序号', className: 'unEditor', width: 40},
                    {
                        field:'category', width: 100, title: '类别', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/intoAndOut/searchHeardInventoryCosting', this.pickerDate)
                       }
                   },{
                        field:'specification', width: 150, title: '实际规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/intoAndOut/searchHeardInventoryCosting', this.pickerDate)
                       }
                   }, {
                        field:'single_count', width: 100, title: '销售支数', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/intoAndOut/searchHeardInventoryCosting', this.pickerDate)
                       }
                   }, {
                        field:'weighing_heavy', width: 100, title: '销售重量', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 5})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/intoAndOut/searchHeardInventoryCosting', this.pickerDate)
                       }
                   },{
                        field:'amount_of_product', width: 100, title: '销售金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 3})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/intoAndOut/searchHeardInventoryCosting', this.pickerDate)
                       }
                   }, {
                        field:'practical_price', width: 100, title: '实收金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 3})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/intoAndOut/searchHeardInventoryCosting', this.pickerDate)
                       }
                   }, {
                        field:'kick_back', width: 100, title: '回扣佣金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 3})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/intoAndOut/searchHeardInventoryCosting', this.pickerDate)
                       }
                   }

                ],
                tableDataMain: [],
                filterData: {},
                sortData: {},
           }
       },
        methods: {
            open(row){
                const{href} = this.$router.resolve({path: '/allWarehouse/allSalesBilling', query: {category: row.category,specification:row.specification,pickerDate:[XEUtils.toDateString(this.pickerDate[0], 'yyyy-MM-dd'),XEUtils.toDateString(this.pickerDate[1], 'yyyy-MM-dd')]}});
                window.open(href,'_blank');
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'kick_back' || column.property === 'practical_price'
                            || column.property === 'single_count' || column.property === 'weighing_heavy'
                            || column.property === 'amount_of_product'
                            || column.property === 'payend' || column.property === 'balance'
                            || column.property === 'other_expenses_price' || column.property === 'kick_back_total'
                        ) {
                            sums.push(this.$_sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            handlePageChange ({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList();
           },

            editMethod ({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate (value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName ({rowIndex}) {
                if (rowIndex%2 === 1) {
                    return 'success-row';
               } else{
                    return '';
               }
           },
            onSubmit() {
                //console.log('submit!');
           },
            searchList() {
                this.tablePage.currentPage = 1
                this.getList();
           },
            getList() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'POST',
                    url: '/admin/productSalesList/search',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        pickerDate: this.pickerDate,
                        sortData: this.sortData,
                        filterData: this.filterData,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           }
       },
        created () {
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.searchList();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
</style>
